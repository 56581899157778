import { navigate } from 'gatsby';
import React, { useEffect } from 'react';
import Container from '../components/layout/default/container';
import SEO from '../components/layout/seo';
// markup
const IndexPage = () => {

  useEffect(() => {
    navigate('/rum-bucket/bowland-1-2/38', { replace: true });
    // navigate('/rum-bucket/bowland-1-2/38');
  }, []);


  return (
    <Container>
      <SEO title="Bowland" />      
    </Container>
  );
}

export default IndexPage
